<template>
  <div class="md-layout md-alignment-center-center">
      <router-view/>
  </div>
</template>
<style lang="css" scoped>
  .md-layout-item {
    height: 72px;
    margin-top: 8px;
    margin-bottom: 8px;
    transition: .3s ;
  }
  input {
    color: black !important;
    -webkit-text-fill-color: black !important;
  }

</style>
<script>
export default {
  name: 'Login',
};
</script>
