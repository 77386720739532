/* eslint-disable no-param-reassign */
import Vue from 'vue';
import VueMaterial from 'vue-material';
import Axios from 'axios';
import Swal from 'sweetalert2';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import 'vue-material/dist/vue-material.min.css';
// import 'vue-material/dist/theme/default.css';
import 'material-design-icons/iconfont/material-icons.css';

Vue.prototype.$swal = Swal;
Vue.prototype.$http = Axios.create({
  onUploadProgress: (progressEvent) => {
    store.dispatch('status_request', { progress: 0 });
    const p = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    store.dispatch('status_request', { progress: p });
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

Vue.prototype.$http.interceptors.request.use(
  (request) => {
    // const user = localStorage.getItem('user');
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // eslint-disable-next-line no-param-reassign
      // eslint-disable-next-line dot-notation
      request.headers['Authorization'] = `JWT ${user.token}`;
      request.headers['Accept-Language'] = 'es';
      // requestHandler(request, store.state.user.token);
    }

    return request;
  },
);

Sentry.init({
  Vue,
  dsn: 'https://983c62f98e0141b5a54ca50a296d314a@o152665.ingest.sentry.io/6209457',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['conta.diegodiazgt.com', /^\//],
    }),
  ],
});

Vue.prototype.$http.interceptors.request.use((config) => {
  store.state.isLoading = true;
  return config;
}, (error) => {
  store.state.isLoading = false;
  return Promise.reject(error);
});

Vue.config.productionTip = false;
Vue.use(VueMaterial);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
