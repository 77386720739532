import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { Auth: false, title: 'Login' },
    beforeEnter: (to, from, next) => {
      // Si existe un token, la sesion existe, por lo cual, redirecciona a home
      // if (window.localStorage.getItem('_token')) {
      if (window.localStorage.getItem('_token')) {
        next({ path: '/' });
      } else {
        next();
      }
    },
  },
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { Auth: true, title: 'Inicio' },
  },
  {
    path: '/detalle_ventas',
    name: 'detalle_ventas',
    component: () => import('../views/DetalleVentas.vue'),
    meta: { Auth: true, title: 'Detalle ventas' },
  },
  {
    path: '/ventas',
    name: 'ventas',
    component: () => import('../views/Ventas.vue'),
    meta: { Auth: true, title: 'Ventas' },
  },
  {
    path: '/form_ventas',
    name: 'Formulario_venta',
    component: () => import('../views/FormVenta.vue'),
    meta: { Auth: true, title: 'Formulario venta' },
  },
  {
    path: '/form_ventas/:id?',
    name: 'Formulario_venta_edit',
    component: () => import('../views/FormVenta.vue'),
    meta: { Auth: true, title: 'Formulario venta' },
  },
  {
    path: '/compras',
    name: 'compras',
    component: () => import('../views/BookPurchases.vue'),
    meta: { Auth: true, title: 'Compras' },
  },
  {
    path: '/form_compras',
    name: 'Formulario_compra',
    component: () => import('../views/FormBookPurchases.vue'),
    meta: { Auth: true, title: 'Compras' },
  },
  {
    path: '/form_compras/:id',
    name: 'Formulario_compra_edit',
    component: () => import('../views/FormBookPurchases.vue'),
    meta: { Auth: true, title: 'Compras' },
  },
  {
    path: '/banks',
    name: 'banks',
    component: () => import('../views/Banks.vue'),
    meta: { Auth: true, title: 'Bancos' },
  },
  {
    path: '/detalle_compras',
    name: 'Detalle_compras',
    component: () => import('../views/Purchases.vue'),
    meta: { Auth: true, title: 'Detalle Compras' },
  },
  {
    path: '/period',
    name: 'Periodo',
    component: () => import('../views/Period.vue'),
    meta: { Auth: true, title: 'Periodo' },
  },
  {
    path: '/period-sale',
    name: 'periodo_sale',
    component: () => import('../views/PeriodSale.vue'),
    meta: { Auth: true, title: 'Periodo' },
  },
  {
    path: '/fiscal_year',
    name: 'Año fiscal',
    component: () => import('../views/FormFiscalYear.vue'),
    meta: { Auth: true, title: 'Año Fiscal' },
  },
  {
    path: '/fiscal_year_list',
    name: 'Año fiscal lista',
    component: () => import('../views/FiscalYear.vue'),
    meta: { Auth: true, title: 'Año Fiscal' },
  },
  {
    path: '/compras/nuevo',
    name: 'Nueva_compra',
    component: () => import('../views/FormPurchases.vue'),
    meta: { Auth: true, title: 'Nueva Compra' },
  },
  {
    path: '/compras/:id',
    name: 'Editar_compra',
    component: () => import('../views/FormPurchases.vue'),
    meta: { Auth: true, title: 'Editar Compras' },
  },
  {
    path: '/stores/:company',
    name: 'establecimientos',
    component: () => import('../views/Stores.vue'),
    meta: { Auth: true, title: 'Establecimientos' },
  },
  {
    path: '/rubros',
    name: 'rubros',
    component: () => import('../views/Rubros.vue'),
    meta: { Auth: true, title: 'Rubros' },
  },
  {
    path: '/form_rubro/:id?',
    name: 'form rubro',
    component: () => import('../views/FormRubro.vue'),
    meta: { Auth: true, title: 'Formulario rubro' },
  },
  {
    path: '/form_detalle_ventas/nuevo',
    name: 'detalle_ventas_period',
    component: () => import('../views/FormDetalleVenta.vue'),
    meta: { Auth: true, title: 'Detalle ventas' },
  },
  {
    path: '/form_detalle_ventas/:invoice_id',
    name: 'detalle_ventas_edit',
    component: () => import('../views/FormDetalleVenta.vue'),
    meta: { Auth: true, title: 'Detalle ventas' },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { Auth: true, title: 'Configuraciones' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: { Auth: true, title: 'Perfil' },
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/Customers.vue'),
    meta: { Auth: true, title: 'Clientes' },
  },
  {
    path: '/form_employes/:id?',
    name: 'employes',
    component: () => import('../views/FormEmpleados.vue'),
    meta: { Auth: true, title: 'Empleados' },
  },
  {
    path: '/employes',
    name: 'employes2',
    component: () => import('../views/Empleados.vue'),
    meta: { Auth: true, title: 'Empleados' },
  },
  {
    path: '/form_customer/:id?',
    name: 'cliente_form',
    component: () => import('../views/FormCustomer.vue'),
    meta: { Auth: true, title: 'Cliente' },
  },
  {
    path: '/movimientos_bancarios',
    name: 'movimientos_bancarios',
    component: () => import('../views/MovimientosBancarios.vue'),
    meta: { Auth: true, title: 'Bancos' },
  },
  {
    path: '/form_movimientos_bancarios/:id?',
    name: 'form_movimientos_bancarios',
    component: () => import('../views/FormMovimientoBancario.vue'),
    meta: { Auth: true, title: 'Movimientos bancarios' },
  },
  {
    path: '/form_movimientos_bancarios_view/:idview?',
    name: 'form_movimientos_bancarios_view',
    component: () => import('../views/FormMovimientoBancario.vue'),
    meta: { Auth: true, title: 'Movimientos bancarios' },
  },
  {
    path: '/form_bank/:id?',
    name: 'bank_form',
    component: () => import('../views/FormBank.vue'),
    meta: { Auth: true, title: 'Bancos' },
  },
  {
    path: '/providers',
    name: 'providers',
    component: () => import('../views/Providers.vue'),
    meta: { Auth: true, title: 'Proveedores' },
  },
  {
    path: '/base_config',
    name: 'base config',
    component: () => import('../views/BaseConfigCuentas.vue'),
    meta: { Auth: true, title: 'Configuración base' },
  },
  {
    path: '/company/:id?',
    name: 'company',
    component: () => import('../views/Company.vue'),
    meta: { Auth: true, title: 'Empresa' },
  },
  {
    path: '/form_store/:id?',
    name: 'store',
    component: () => import('../views/FormStore.vue'),
    meta: { Auth: true, title: 'Establecimiento' },
  },
  {
    path: '/form_provider/:id?',
    name: 'provider_form',
    component: () => import('../views/FormProvider.vue'),
    meta: { Auth: true, title: 'Proveedor' },
  },
  {
    path: '/cuentas_contables/',
    name: 'Cuentas Contables',
    component: () => import('../views/FormCuentasContables.vue'),
    meta: { Auth: true, title: 'Cuentas Contables' },
  },
  {
    path: '/form_detalle_ventas/view/:invoice_idview',
    name: 'detalle_ventas_view',
    component: () => import('../views/FormDetalleVenta.vue'),
    meta: { Auth: true, title: 'Detalle ventas' },
  },
  {
    path: '/partidas/',
    name: 'Partidas',
    component: () => import('../views/Partida.vue'),
    meta: { Auth: true, title: 'Partidas' },
  },
  {
    path: '/partidas/nuevo',
    name: 'Partidas Nuevo',
    component: () => import('../views/FormPartida.vue'),
    meta: { Auth: true, title: 'Partidas' },
  },
  {
    path: '/partidas/:id',
    name: 'Partida Editar',
    component: () => import('../views/FormPartida.vue'),
    meta: { Auth: true, title: 'Partida Editar' },
  },
  {
    path: '/libro_diario',
    name: 'Libro Diario',
    component: () => import('../views/FormBookDiary.vue'),
    meta: { Auth: true, title: 'Libro Diario' },
  },
  {
    path: '/libro_diario/:id',
    name: 'Libro Diario_edit',
    component: () => import('../views/FormBookDiary.vue'),
    meta: { Auth: true, title: 'Libro Diario Editar' },
  },
  {
    path: '/compras/view/:idview',
    name: 'Detalle compras',
    component: () => import('../views/FormPurchases.vue'),
    meta: { Auth: true, title: 'Detalle comporas' },
  },
  {
    path: '/books',
    name: 'Libros',
    component: () => import('../views/FormBooks.vue'),
    meta: { Auth: true, title: 'Libros' },
  },
  {
    path: '/create_setting',
    name: 'Formulario Configurar Cuentas',
    component: () => import('../views/FormConfiguracionCuentasContables.vue'),
    meta: { Auth: true, title: 'Fotmulario Configurar Cuentas' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.Auth && !window.localStorage.getItem('_token')) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
