/* eslint-disable no-alert */
/* eslint-disable no-console */

import { register } from 'register-service-worker';
import Swal from 'sweetalert2';

const notifyUserAboutUPdate = (worker) => {
  Swal.fire({
    title: 'Contenido nuevo',
    text: 'Hay contenido nuevo, debemos actualizar.',
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, actualizar.',
    cancelButtonText: 'No',
  }).then((result) => {
    if (result.value) {
      worker.postMessage({ action: 'skipWaiting' });
      Swal.fire(
        'Actualizado!',
        'Gracias por actualizar, esta página se recargará en 3 segundos...',
        'success',
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  });
};

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-restricted-globals
      // const confirmationResult = confirm('New content found! Do you want to reload the app?');
      // if (confirmationResult) registration.waiting.postMessage({ action: 'skipWaiting' });
      notifyUserAboutUPdate(registration.waiting);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
  let refreshing;
  if (typeof window.domLoadEventFired !== 'undefined') {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) return;
      window.location.reload();
      refreshing = true;
    });
  }
  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener('message', (e) => {
    /* eslint-disable-next-line no-restricted-globals */
    if (e.data.action === 'skipWaiting') self.skipWaiting();
  });
}
